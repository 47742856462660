import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ValidarTokenGuard } from './core/guard/validar-token.guard';

const routes: Routes = [
  {
    path: 'no-registrado',
    loadChildren: () =>
      import('./pages/no-registrado/no-registrado.module').then(
        (m) => m.NoRegistradoModule
      ),
  },

  {
    path: 'compartida',
    loadChildren: () =>
      import('./compartida/compartida.module').then((m) => m.CompartidaModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
    canActivate: [ValidarTokenGuard],
  },

  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'agenda',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
