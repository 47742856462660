import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TokenValidateResponse } from '../interfaces/token-validate-response';
import { Router } from '@angular/router';
import { ValidatedTokenService } from '../services/validated-token.service';

@Injectable({
  providedIn: 'root',
})
export class ValidarTokenGuard implements CanActivate {
  user!: TokenValidateResponse;
  token: string = '';
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private router: Router,
    private readonly validatedTokenService: ValidatedTokenService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | boolean {
    this.token =
      state.url.split('/')[2] === 'token'
        ? state.url.split('/')[3]
        : localStorage.getItem('token')!;
    return this.typePerson(state);
  }

  typePerson(state: RouterStateSnapshot): Observable<boolean> {
    if (isPlatformBrowser(this.platformId)) {
      if (!localStorage.getItem('token')) {
        localStorage.setItem('token', this.token);
      }
    }
    return this.validatedTokenService
      .validatedToken(this.token)
      .pipe(
        map((resp) => {
          this.user = resp as TokenValidateResponse;
          console.log(state.url);
          switch (this.user.user.type) {
            case 1:
              return true;
            case 4:
              switch (state.url) {
                case '/agenda':
                  return true;
                case '/billing':
                  return true;
                case '/operarios':
                  return true;
                case '/availability':
                  return true;
                default:
                  this.router.navigateByUrl('/agenda');
                  return false;
              }
            case 6:
              switch (state.url) {
                case '/metricas':
                  return true;
                default:
                  this.router.navigateByUrl('/metricas');
                  return false;
              }
            default:
              this.router.navigateByUrl('/no-registrado');
              return false;
          }
        })
      )
      .pipe(
        catchError(() => {
          if (isPlatformBrowser(this.platformId)) {
            if (localStorage.getItem('token')) {
              localStorage.removeItem('token');
            }
          }
          this.router.navigateByUrl('/no-registrado');
          return [false];
        })
      );
  }
}
